@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: "Baskerville";
  src: url('../assets/fonts/Baskerville.ttf') format('truetype'),
    url('../assets/fonts/Baskerville.woff2') format('woff2');
}

