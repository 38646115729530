@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap);
@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Regular.73f0a88b.woff2) format('woff2'),
    url(/static/media/Roboto-Regular.878ad715.woff) format('woff');
}

@font-face {
  font-family: "Baskerville";
  src: url(/static/media/Baskerville.501ade6e.ttf) format('truetype'),
    url(/static/media/Baskerville.cb2ec161.woff2) format('woff2');
}


